@use "./colors.scss" as tokens;

.login-container {
  overflow: hidden;

  .login-web-image {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/MVS_login_web.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-container {
    width: 70%;
  }

  .tick-login {
    background-image: url("../assets/images/tick-login.png");
    background-repeat: no-repeat;
    background-position: 20px;
    width: 460px;
    height: 480px;
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: -1;
    background-size: contain;
  }

  .btn-login {
    width: 100%;
  }
  .forgot-password-label {
    font-size: 14px;
    font-weight: 400;
    color: tokens.$font-anchor-var-1;
    cursor: pointer;
  }
}

@media (max-width: 576px) {
  .login-container {
    overflow: hidden;

    .form-container {
      width: 90%;
      position: fixed;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .tick-login {
      position: fixed;
      background-position: 0px;
      left: 10px;
      width: 100%;
      height: 480px;
      z-index: -1;
    }
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .login-container {
    overflow: hidden;

    .form-container {
      width: 90%;
      position: fixed;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .tick-login {
      position: fixed;
      background-position: 0px;
      -ms-transform: translateX(10%) translateY(80%);
      -webkit-transform: translate(10%, 80%);
      transform: translate(10%, 80%);
      z-index: -1;
      bottom: 50%;
      z-index: -1;
    }
  }
}

@media (min-width: 1600px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: 10%;
      z-index: -1;
    }
  }
}

@media (max-width: 1200px) and (max-height: 600px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: -60px;
      z-index: -1;
    }
  }
}

@media (min-width: 1024px) and (min-height: 1200px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: -60px;
      z-index: -1;
      bottom: 25%;
    }
  }
}
