@use "./fonts.scss";
@use "./colors.scss" as tokens;
@use "./login.scss";
@use "./SideBarLeft.scss";
@use "./FirmOnboarding.scss";
@use "./headerNav.scss";
@use "./firm.scss";
@use "./toast.scss";
@use "./modalPopupType2.scss";
@use "./AgGrid.scss";
@use "./FirmLandingPage.scss";
@use "./TabButton.scss";
@use "./OtpVerification.scss";
@use "./FirmAdminLandingPage.scss";
@use "./superAdmin.scss";
@use "./bulkUpload.scss";
@use "./ChangePasword.scss";
@use "./widgetsStyles.scss";
@use "./Attorney.scss";
@use "./AttorneyLandingPage.scss";
@use "./AttorneyAddClient.scss";
@use "./AttorneyViewClientPage.scss";
@use "./AttorneyEditClientPage.scss";
@use "./previewModalDocument.scss";
@use "./ViewPeopleDetailsPage.scss";
@use "./ReactPdf.scss";
@use "./AttorneyOffCanvasEditProfile.scss";
@use "./ForgotPassword.scss";
@use "./ClientNotification.scss";
@use "./ShareDocumentModal.scss";
@use "./ModalPopupType4.scss";
@use "./ClientSearchSuccessModal.scss";
@use "./ClientChangeAttorneyCanvas.scss";
@use "./DeathClaim.scss";
@use "./FamilyMemberModal.scss";
@use "./FirmReportsPage.scss";
@use "./FirmAdminReportsPage.scss";
@use "./additionalProfessional.scss";
@use "./MedicalProfessional.scss";
@use "./FoldersPageTitleBar.scss";
@use "./ClientShareBankDetails.scss";
@use "./modalPopupType6.scss";
@use "./ExternalUserLandingPage.scss";
@use "./PersonalUseModal.scss";
@use "./ClientPersonalUse.scss";
@use "./ClientBulkTransfer.scss";
@use "./NewFolderModal.scss";
@use "./wire-transfer.scss";
@use "./modalPopupType8.scss";
@use "./AiRecommendations.scss";
@use "./Executor.scss";
@use "./ShortcertificateModal.scss";

/* custom navbar design */
.custom-navbar {
  height: 4rem;
  background-color: tokens.$header-background-color;
  position: fixed !important;
  width: 100%;
  z-index: 9999;

  .custom-navbar-text {
    color: tokens.$font-brand;
    font-size: 20px;
  }

  .custom-nav-divider {
    width: 2px;
    height: auto;
    background: tokens.$nav-divider;
    margin: 0 auto;
  }

  .custom-navbar-profile {
    display: inline-block;
    margin-right: 5px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 2px solid tokens.$white-color;
    vertical-align: middle;
    overflow: hidden;
    background-color: tokens.$page-sub-title-bar-border;

    .custom-navbar-img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 6px;
      top: 5px;
    }
  }

  .custom-dropdown-menu {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 16px;

    .dropdown-menu {
      padding: 0;
      box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.25);

      .dropdown-item {
        padding: 10px 10px;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .dropdown-toggle {
      &::after {
        display: inline-block;
        vertical-align: middle;
        content: "";
        background-image: url("../assets/images/Arrow-Down.png");
        background-size: cover;
        border: none;
        width: 20px;
        height: 20px;
      }
    }

    .dropdown-icon-image {
      width: 26px;
      height: 26px;
    }
  }

  .custom-navbar-cart {
    display: inline-block;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    overflow: hidden;

    .custom-navbar-img {
      width: 100%;
      height: 100%;
    }
  }

  .custom-nav-link {
    color: tokens.$white-color;
    font-size: 1rem;
    font-weight: 400;

    &.active {
      color: #fff;
    }
  }

  .navbar-nav {
    .nav-link {
      color: #fff !important;

      &.active {
        span {
          border-bottom: 3px solid tokens.$form-title-underline-new;
          padding-bottom: 0.5rem;
        }
      }

      &:hover {
        span {
          border-bottom: 3px solid tokens.$form-title-underline-new;
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .btn-logo {
    .btn:hover {
      color: #fff;
    }
  }

  .offcanvas-body-flex {
    display: flex;
    justify-content: center;
  }

  .navbar-toggler-icon {
    vertical-align: unset;
  }

  .cursor-point {
    cursor: pointer;
  }
}

.offcanvas {
  z-index: 9999 !important;
}

.offcanvas-header {
  border: 1px solid rgb(22, 26, 73);
}

.offcanvas-header-pos::after {
  position: absolute;
  content: "";
  display: block;
  width: 86%;
  top: 70px;
  left: 7%;
  border-bottom: 1px solid #fff;
}

/* Form Content Design */
.custom-form {
  font-family: "HelveticaRegular", "Arial", sans-serif;

  .form-title {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    color: tokens.$font-blue-new;
    font-size: 1.5rem;
    font-weight: 700;

    .form-text-underline {
      text-decoration: none;
      position: relative;
      display: inline-block;
      padding-bottom: 0.375rem;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 0.125rem solid tokens.$form-title-underline-new;
      }

      &.type-2 {
        &:after {
          width: 25%;
          border-bottom: 0.25rem solid tokens.$form-title-underline-new;
        }
      }
    }

    &.chg-password {
      font-size: 2rem;
    }

    &.f-s-36px {
      font-size: 2.25rem;
    }
  }

  .form-label {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: tokens.$form-color-general;

    &.required {
      &::after {
        content: "*";
        color: tokens.$form-error-general;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        left: 2px;
        bottom: 1px;
      }
    }

    &.f-s-1rem {
      font-size: 1rem;
    }
  }

  .form-title-full {
    color: tokens.$form-title-full;
    font-size: 1.25rem;
    font-weight: 400;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 97%;
      border-bottom: 0.125rem solid tokens.$form-title-full-border;
    }
  }

  .form-select.form-input.is-invalid {
    background-image: url("../assets/images/Arrow-Down.png");
    background-size: auto;
  }

  &.type-2 {
    .phone-input-invalid-container {
      .react-tel-input .form-control {
        font-size: 1rem;
        border: 0.063rem solid tokens.$form-error-general !important;
        height: 2.25rem;
        border-radius: 2px;
        color: tokens.$form-color-general;
        width: 100%;
      }
      :focus {
        box-shadow: 0 0 0 4px rgb(248, 207, 207);
      }
    }
    .react-tel-input {
      .selected-flag:hover {
        background-color: transparent;
      }
      .country-list {
        overflow: hidden;
        margin: 0;
        width: 250px;
      }
      .special-label {
        display: none;
      }
      .form-control {
        font-size: 1rem;
        border: 0.063rem solid tokens.$form-input-border-new;
        height: 2.25rem;
        border-radius: 2px;
        color: tokens.$form-color-general;
        width: 100%;
      }
      .flag-dropdown {
        background-color: transparent;
        border: 0;
        &:hover {
          background-color: transparent;
          border: 0;
        }
      }
      .flag-dropdown.open .selected-flag {
        background: none;
      }
    }

    .form-input {
      font-size: 1rem;
      border: 0.063rem solid tokens.$form-input-border-new;
      height: 2.25rem;
      padding: 0.25rem 2rem 0.25rem 1rem;
      border-radius: 2px;
      color: tokens.$form-color-general;

      &.form-control {
        &.is-invalid {
          border: 0.063rem solid tokens.$form-error-general;
        }
      }

      &::placeholder {
        color: tokens.$form-placeholder-color-type2;
        font-size: 0.875rem;
      }

      &.h-3rem {
        height: 3rem;
      }
    }

    .form-select {
      &.is-invalid {
        border: 0.063rem solid tokens.$form-placeholder-error-color;
      }
    }
  }

  .select-placeholder:has(option[value=""]:checked) {
    font-size: 0.875rem;
  }

  .form-input {
    background-color: white;
    font-size: 1rem;
    font-weight: 400;
    border: 0.125rem solid tokens.$form-input-border-new;
    color: tokens.$form-color-general;
    height: 3rem;
    padding: 0.25rem 0.75rem 0.25rem 1rem;
    border-radius: 4px;

    &.is-invalid {
      background-image: none;
      border-color: tokens.$form-error-general;
    }

    &:focus {
      outline: none;
      border: 0.125rem solid tokens.$form-input-border-new;
    }

    &::placeholder {
      color: tokens.$form-placeholder-color;
      font-size: 0.875rem;
    }

    &.input_number {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }

  .invalid-feedback {
    color: tokens.$form-error-general;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .form-input-error {
    font-size: 0.875rem;
    border: 0.063rem solid tokens.$form-input-border;
    height: 3rem;
    padding: 0.5rem 0.75rem 0.5rem 1rem;
    border-radius: 4px;

    &::placeholder {
      color: tokens.$form-placeholder-error-color;
    }
  }

  .custom-form-label {
    font-size: 1rem;
    color: tokens.$form-label-text;
    font-weight: 400;
    margin-bottom: 0.2rem;
  }

  .custom-form-helper-text {
    font-size: 0.875rem;
    color: tokens.$form-helper-text-normal;
    font-weight: 400;

    .bold {
      color: tokens.$form-label-text;
      font-weight: 600;
    }
  }

  .button-anchor {
    cursor: pointer;

    .var-1 {
      color: tokens.$font-anchor-var-1;
      font-weight: 400;
      font-size: 0.875rem;
    }

    .var-2 {
      color: tokens.$font-anchor-var-2;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .form-text {
    color: tokens.$font-blue;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 10px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &.change-password {
    width: 70%;
  }

  .forgot-password-success {
    padding-top: 100px;
    .success-text {
      font-size: 14px;
      font-weight: 400;
    }

    .go-login-btn {
      width: 50%;
    }
  }

  .f-w-400 {
    font-weight: 400;
  }
}

/* Custom Button Design */
.custom-button {
  &.btn-standard {
    font-size: 1.25rem;
    font-family: "HelveticaRegular", "Arial", sans-serif;
    border-radius: 0.25rem;
    background-color: tokens.$form-button-blue;
    padding: 0.313rem 1rem 0.25rem 1rem;
    height: 2.625rem;
    border: 1px solid tokens.$form-button-blue;
    &.btn-sharereceive{
      .btn-primary {
        all: unset !important;
      }
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    }
    &.next-button {
      width: 107px;
      font-size: 18px;
    }

    &.ok-button {
      font-size: 16px;
      font-weight: 400;
    }
    &:hover {
      background-color: tokens.$form-button-blue-hover;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }

    &:focus {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;
    }
    &:disabled {
      background-color: rgba(16, 30, 63, 1);
      border: none;
    }
  }
  &.btn-new-firm {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 1rem;
    width: 12.1875rem;
    height: 2.5rem;
    padding: 5px 16px 4px 16px;

    &:hover {
      background-color: tokens.$form-button-blue-hover;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }

    &:focus {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;
    }
    &.new-folder{
      padding: 5px 2px 4px 2px;
    }
  }

  &.btn-nobg {
    font-size: 1rem;
    font-weight: 700;
    background-color: tokens.$white-color;
    color: tokens.$font-blue-new;
    border: 1px solid tokens.$font-blue-new;
    padding: 0.313rem 1rem 0.25rem 1rem;
    height: 2.625rem;
    border-radius: 4px;
    &.back-btn {
      width: 110px;
      font-size: 16px;
    }
    &:hover {
      background-color: tokens.$form-button-blue-hover;
      border: 1px solid tokens.$form-button-blue;
    }

    &:active {
      background-color: tokens.$form-button-blue-hover;
      box-shadow: none;

      &:focus {
        background-color: tokens.$form-button-blue-hover;
        box-shadow: none;
      }
    }
  }

  &.btn-update {
    font-size: 1rem;
  }
  &.btn-update-tabs {
    width: max-content;
    font-size: 1rem;
  }
  &.btn-disable {
    background-color: rgba(138, 140, 164, 255) !important;
    border: none;
    pointer-events: none;
  }
  &.custom-file-upload {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 1rem;
    //width: 12.1875rem;
    height: 2.25rem;
    padding: 5px 16px 4px 16px;
    color: #fff;
    width: 160px;
    &.btn-change-professional {
      height: 2.5rem;
    }
  }
  &.custom-file-upload-deathcertificate {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 1rem;
    text-align-last: center;
    height: 2.5rem;
    padding: 5px 16px 4px 16px;
    color: #fff;
    width: 160px;
    &.btn-change-professional {
      height: 2.5rem;
    }
  }
  &.plus-btn {
    font-size: 1rem;
    padding: 8px 10px 10px 10px;
    height: 1rem;
    width: 1rem;
  }
}

/* Form Bottom Text */
.form-bottom-text {
  color: tokens.$form-bottom-text;
  font-size: 2.5rem;
  right: 3.25rem;
  bottom: 2.188rem;
}

/* Breadcrumb Design */
.custom-breadcrumb {
  .custom-breadcrumb-item {
    cursor: pointer;

    &.active {
      color: tokens.$font-blue-new;
      cursor: auto;
    }

    &::before {
      color: tokens.$font-blue-new !important;
    }

    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: tokens.$breadcrum-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .span-item{
    cursor: pointer;

    &.active {
      color: tokens.$font-blue-new;
      cursor: auto;
    }

    &::before {
      content: "/";
      padding-left:.5rem;
      padding-right: .5rem;
    }

    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    color: tokens.$breadcrum-color;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/* Tab Design*/
.signup-tabs {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 6px;

  .tab-link {
    font-size: 14px;
    font-weight: 600;
    color: tokens.$font-blue;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active-tab {
    border-bottom: 3px solid tokens.$active-tab-border;
  }
}

/* Upload document page widget*/
.folder-Container {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  padding: 0;
  width: 200px;
  min-height: 120px;
  border-bottom: 4px solid tokens.$dashboard-active-tab-color;
  box-shadow: 0 3px 10px tokens.$dashboard-tab-border;
  border-right: 1px solid tokens.$dashboard-tab-border;
  border-left: 2px solid tokens.$dashboard-tab-border;
  border-radius: 4px;

  .folder-card {
    padding: 4px 8px;

    .folder-body {
      padding: 0;
    }

    .folder-name {
      font-size: 14px;
      font-weight: 600;
    }

    .folder-description {
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.modal-container-no-radius {
  border-radius: 0 !important;
  border: 2px solid #d4dadd !important;
  border-left: 6px solid #348347 !important;

  .no-border {
    border: 0;
  }

  .header-title {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 15px;
    font-weight: 700;
  }
}

.modal-container {
  &.success-alert {
    width: 530px;
    height: 282px;
  }

  .no-border {
    border: 0;
  }

  .header-title {
    .btn-close {
      height: 1rem;
      width: 1rem;
    }
  }

  .text-align-center {
    color: tokens.$form-color-general;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
}

/* date picker icon*/
.react-datepicker__calendar-icon {
  width: 18px;
  padding: 0;
  right: 1%;
  top: 16%;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    width: inherit;
  }
}

/*

/* Loading Spinner*/
.backdrop-spinner {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  background-size: contain;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99999999 !important;
}

#tooltip-password {
  background-color: tokens.$popover-bg;
  &.bs-popover-bottom {
    .popover-arrow {
      &::after {
        border-bottom-color: tokens.$popover-bg;
      }

      &::before {
        border-bottom-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-top {
    .popover-arrow {
      &::after {
        border-top-color: tokens.$popover-bg;
      }

      &::before {
        border-top-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-left {
    .popover-arrow {
      &::after {
        border-left-color: tokens.$popover-bg;
      }

      &::before {
        border-left-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-right {
    .popover-arrow {
      &::after {
        border-right-color: tokens.$popover-bg;
      }

      &::before {
        border-right-color: tokens.$popover-bg;
      }
    }
  }

  &.bs-popover-start {
    .popover-arrow {
      &::after {
        border-left-color: tokens.$popover-bg;
      }

      &::before {
        border-left-color: tokens.$popover-bg;
      }
    }
  }

  .popover-body {
    color: tokens.$tooltip-body;
    font-size: 12px;
    font-weight: 400;
    font-family: "HelveticaRegular", "Arial", sans-serif;

    ul {
      margin: 0;
      padding: 0 5px 0 5px;

      li {
        color: tokens.$tooltip-body;
      }
    }
  }
}

.background-mvs-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: -2;

  &.type-1 {
    background-image: url("../assets/images/v-bg.png");
    width: 24rem;
    height: 24rem;
    bottom: 0;
    right: 10px;
  }
  &.type-2 {
    background-image: url("../assets/images/v-bg.png");
    width: 24rem;
    height: 24rem;
    bottom: 0;
    right: 50px;
    z-index: 0 !important;
  }
}

.modal-backdrop {
  z-index: 999999 !important;
}

.modal {
  z-index: 999999 !important;
}

.Toastify__toast-container {
  z-index: 9999999 !important;
}

.progress-doc-container {
  position: fixed;
  bottom: 30px;
  right: 10px;
  background: rgb(232, 232, 237);
  padding: 20px;
  border-radius: 7px;
}

/*
Breakpoint	Class infix	Dimensions
X-Small	None	<576px
Small	sm	≥576px
Medium	md	≥768px
Large	lg	≥992px
Extra large	xl	≥1200px
Extra extra large	xxl	≥1400px
*/

/* media Queries */
@media (max-width: 576px) {
  .custom-navbar {
    height: 4rem;

    //position: fixed;
    .navbar-toggle-bg-icon {
      &:focus {
        box-shadow: unset;
      }
    }
  }
  .tooltip-personaluse {
    display: none !important;
  }
  .tooltip-new-folder{
    display: none !important;
  }
  .attorney-new-folder-tooltip{
    display: none !important;
  }
  .custom-form {
    .mb-space {
      margin-bottom: 1rem;
    }

    &.change-password {
      width: 100%;
    }

    .text-align-center {
      text-align: center;
    }
  }
  .background-mvs-image {
    &.type-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .close-button-clientedit {
    width: 5px !important;
    height: 5px !important;
    padding-top: 64px !important;
    color: black !important;
    top: 48px;
    right: 20px;
  }
  .tooltip-personaluse {
    display: none !important;
  }
  .tooltip-new-folder{
    display: none !important;
  }
  .attorney-new-folder-tooltip{
    display: none !important;
  }
}

@media only screen and (max-width: 960px) and (min-width: 600px) {
  .custom-navbar {
    .navbar-toggle-bg-icon {
      &:focus {
        box-shadow: unset;
      }
    }
  }

  .custom-form {
    &.change-password {
      width: 60%;
    }
  }
  .add-executor-button {
    margin-bottom: 30px;
  }
  .tooltip-personaluse {
    display: none !important;
  }
  .tooltip-new-folder{
    display: none !important;
  }
  .attorney-new-folder-tooltip{
    display: none !important;
  }
}

@media (max-width: 960px) {
  .background-mvs-image {
    &.type-1 {
      bottom: 10px;
      right: 0;
    }
  }
  .tooltip-personaluse{
    display: none !important;
  }
  .tooltip-new-folder{
    display: none !important;
  }
  .attorney-new-folder-tooltip{
    display: none !important;
  }
}

.landing-page-container {
  width: 100%;
  .landing-page-bg {
    background: #f4f4f4;
    width: 100% p {
      margin-bottom: 30px;
    }
    .landing-page-img {
      width: 100%;
      height: 100%;
    }
  }
  .landing-page-content {
    font-size: 18px;
    font-weight: 700;
  }
}
.nav-tabs-custom {
  background: none !important;
  //styleName: Body 2 - (Semibold) - 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  &.nav-tabs {
    background: white;
    box-shadow: 2px 0px 12px 2px rgba(0, 0, 0, 0.1);
    width: fit-content;
  }

  .nav-link {
    background-color: transparent !important;
    color: black;
    text-transform: none;
    border: none !important;

    &.active {
      background-color: transparent !important;
      border-bottom: 5px solid black !important;
      font-weight: 600;
    }

    &:focus {
      border: none;
      background-color: transparent !important;
    }

    &:hover {
      border: none;
      border-bottom: 1px solid black;
      color: black;
    }
  }
}

.client-edit-header {
  margin-bottom: -55px;
}
.divider-firm-tab {
  width: 100%;
  height: 0.02rem;
  background-color: rgba(208, 211, 214, 1);
  margin-top: 3px;
  .inner-tab {
    width: 100%;
  }
}

.btn-edit-client {
  width: "90px" !important;
  position: "absolute" !important;
  right: "10px" !important;
}

.close-button-clientedit {
  width: 5px !important;
  height: 5px !important;
  color: black !important;
}

.edit-family-title {
  font-family: "HelveticaRegular", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
}
.modal-popup-type-5 {
  --bs-modal-footer-gap: 0 !important;
  --bs-modal-header-padding: 0 !important;
  .modal {
    --bs-modal-header-padding: 0;
  }
  .btn-close {
    position: absolute;
    z-index: 999999999 !important;
    top: 12px;
    right: 12px;
  }
  .is-will-uploaded {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.1px;
    text-align: center;
  }

  .is-personal-use{
    font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  text-align: center;
  margin-bottom: 20px;
  }

  .modal-footer-willupload {
    font-family: "HelveticaRegular", "Arial", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    text-align: center;
  }
}

#tooltip-changeattorney {
  z-index: 9999 !important;
  height: 50px;
  width: 13rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-body-padding-x: 0.5rem;
    background-color: tokens.$popover-bg;
    &.bs-popover-bottom {
      .popover-arrow {
        &::after {
          border-bottom-color: tokens.$popover-bg;
        }
  
        &::before {
          border-bottom-color: tokens.$popover-bg;
        }
      }
    }
  
    &.bs-popover-top {
      .popover-arrow {
        &::after {
          border-top-color: tokens.$popover-bg;
        }
  
        &::before {
          border-top-color: tokens.$popover-bg;
        }
      }
    }
  
    &.bs-popover-left {
      .popover-arrow {
        &::after {
          border-left-color: tokens.$popover-bg;
        }
  
        &::before {
          border-left-color: tokens.$popover-bg;
        }
      }
    }
  
    &.bs-popover-right {
      .popover-arrow {
        &::after {
          border-right-color: tokens.$popover-bg;
        }
  
        &::before {
          border-right-color: tokens.$popover-bg;
        }
      }
    }
  
    &.bs-popover-start {
      .popover-arrow {
        &::after {
          border-left-color: tokens.$popover-bg;
        }
  
        &::before {
          border-left-color: tokens.$popover-bg;
        }
      }
    }
  
    .popover-body {
      color: tokens.$tooltip-body;
      font-size: 12px;
      font-weight: 400;
      font-family: HelveticaRegular;
  
      ul {
        margin: 0;
        padding: 0 5px 0 5px;
  
        li {
          color: tokens.$tooltip-body;
        }
      }
    }
}

.tooltip-personaluse {
  z-index: 9999 !important;
  border: rgba(22, 26, 73, 1) !important;
  background-color: rgba(22, 26, 73, 1) !important;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.8rem;
  --bs-tooltip-margin-top: 10rem;
  --bs-tooltip-margin-right: 10rem;
  --bs-tooltip-bg: rgba(22, 26, 73, 1) !important;
  --bs-tooltip-max-width: 300px;
  &.tooltip {
    background-color: rgba(22, 26, 73, 1) !important;
    width: 320px;
    height: 150px;
    font-size: 12px;
    border-radius: 4px !important;
    padding-left: 9px !important;
    padding-top:9px !important;
    margin-top: 3rem;
    margin-right: 2px;
    .tooltip-inner {
      background-color: rgba(22, 26, 73, 1) !important;
      box-shadow: rgba(22, 26, 73, 1) 0px 1px 3px 1px;
      height: 100px;
      width: 300px;
      --bs-tooltip-max-width: 300px;
    }
  }
  .tooltip-arrow{
    position: absolute !important;
      top: 20px !important;
      transform: translate3d(0px, 0px, 0px) !important;
  }
  &.show {
    .tooltip-arrow {
      position: absolute;
      top: 0px;
      transform: translate3d(0px, 0px, 0px);

      &::before {
        border-left-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
        border-top-color: transparent !important;
        border-right-color: transparent !important;
      }
    }
  }

  .tooltip-arrow::before {
    border-left-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
    border-top-color: transparent !important;
    border-right-color: transparent !important;
  }

  .popover-body {
    color: rgba(22, 26, 73, 1) !important;
    font-size: 12px;
    font-weight: 400;
    font-family: HelveticaRegular;

    ul {
      margin: 0;
      padding: 0 5px 0 5px;

      li {
        color: rgba(22, 26, 73, 1) !important;
      }
    }
  }
}
.attorney-new-folder-tooltip{
  z-index: 9999 !important;
  border: rgba(22, 26, 73, 1) !important;
  background-color: rgba(22, 26, 73, 1) !important;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.8rem;
  --bs-tooltip-margin-top: 10rem;
  --bs-tooltip-margin-right: 10rem;
  --bs-tooltip-bg: rgba(22, 26, 73, 1) !important;
  --bs-tooltip-max-width: 300px;
  &.tooltip {
    background-color: rgba(22, 26, 73, 1) !important;
    width: 200px;
    height: 32px;
    font-size: 12px;
    border-radius: 4px !important;
    padding-left: 9px !important;
    padding-top:2px !important;
   // margin-top: 3.75rem;
    margin-right: 2px;
    .tooltip-inner {
      background-color: rgba(22, 26, 73, 1) !important;
      box-shadow: rgba(22, 26, 73, 1) 0px 1px 3px 1px;
      //height: 100px;
      width: 180px;
      --bs-tooltip-max-width: 300px;
    }
  }
  .tooltip-arrow{
    position: absolute !important;
      top: 10px !important;
      transform: translate3d(0px, 0px, 0px) !important;
  }
  &.show {
    .tooltip-arrow {
      position: absolute;
      top: 0px;
      transform: translate3d(0px, 0px, 0px);

      &::before {
        border-left-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
        border-top-color: transparent !important;
        border-right-color: transparent !important;
      }
    }
  }

  .tooltip-arrow::before {
    border-left-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
    border-top-color: transparent !important;
    border-right-color: transparent !important;
  }

  .popover-body {
    color: rgba(22, 26, 73, 1) !important;
    font-size: 12px;
    font-weight: 400;
    font-family: HelveticaRegular;

    ul {
      margin: 0;
      padding: 0 5px 0 5px;

      li {
        color: rgba(22, 26, 73, 1) !important;
      }
    }
  }
}
.tooltip-new-folder{
  z-index: 9999 !important;
    // border: rgba(22, 26, 73, 1) !important;
    // background-color: rgba(22, 26, 73, 1) !important;
    // --bs-tooltip-arrow-width: 0.8rem;
    // --bs-tooltip-arrow-height: 0.8rem;
    // --bs-tooltip-margin-top: 10rem;
    // --bs-tooltip-margin-right: 10rem;
    // --bs-tooltip-bg: rgba(22, 26, 73, 1) !important;
    // --bs-tooltip-max-width: 300px;
  position: absolute !important;
    inset: 0px auto auto 0px !important;
    //transform: translate3d(422%, 245%, 0) !important;
    top: -56px !important;  
  &.tooltip {
    //background-color: rgba(22, 26, 73, 1) !important;
    width: 200px;
    height: 25px;
    font-size: 12px;
    //border-radius: 4px !important;
    padding-left: 9px !important;
    padding-top:9px !important;
    margin-top: 3rem;
    margin-right: 2px;
    .tooltip-inner {
      background-color: rgba(22, 26, 73, 1) !important;
      //box-shadow: rgba(22, 26, 73, 1) 0px 1px 3px 1px;
      height: 25px;
      //width: 200px;
      --bs-tooltip-max-width: 300px;
      border-radius: 0 !important;
    }
  }
  .tooltip-arrow{
    border-bottom-color: rgba(22, 26, 73, 1) !important;
    position: absolute !important;
    //left: 0px !important;
    transform: translate3d(93.3333px, 8px, 0px) !important;
    //background-color: rgba(22, 26, 73, 1) !important;
  }
  // .tooltip-arrow{
  //   position: absolute !important;
  //     //top: 20px !important;
  //     transform: translate3d(0px, 0px, 0px) !important;
  // }
  &.show {
    .tooltip-arrow {
      position: absolute;
      //top: 0px;
      //transform: translate3d(0px, 0px, 0px);

      &::before {
        border-bottom-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
        border-left-color: transparent !important;
        border-right-color: transparent !important;
      }
    }
  }

  .tooltip-arrow::before {
    border-bottom-color: rgba(22, 26, 73, 1) !important; /* Left arrow color */
    border-left-color: transparent !important;
    border-right-color: transparent !important;
  }

  .popover-body {
    color: rgba(22, 26, 73, 1) !important;
    font-size: 12px;
    font-weight: 400;
    font-family: HelveticaRegular;

    ul {
      margin: 0;
      padding: 0 5px 0 5px;

      li {
        color: rgba(22, 26, 73, 1) !important;
      }
    }
  }
}
